import React from "react";
import { Logo } from "../../assets/export/ExportSvg";
import { Link } from "react-router-dom";
import { LuMenu } from "react-icons/lu";
import Container from "../../layout/Container";

function NavBar() {
	return (
		<div className="bg-primary">
			<Container>
				<div className="flex flex-row items-center justify-between py-7">
					<div className="">
						<Link to="/">
							<Logo width={126} />
						</Link>
					</div>

					<div className="lg:hidden">
						<LuMenu className="text-white w-8 h-8" />
					</div>

					<ul className="hidden lg:flex items-center gap-6">
						{navMenu.map((menu, index) => (
							<li key={index}>
								<a
									href={menu.url}
									className="text-primary font-headingMedium text-[17px]">
									{menu.text}
								</a>
							</li>
						))}
					</ul>
				</div>
			</Container>
		</div>
	);
}

export default NavBar;

const navMenu = [
	{
		text: "Blog",
		url: "https://leather.io/blog",
	},
	{
		text: "Learn",
		url: "https://leather.io/learn",
	},
	{
		text: "User guides",
		url: "https://leather.io/user-guides",
	},
	{
		text: "Developer docs",
		url: "https://leather.io/developer-docs",
	},
	{
		text: "FAQs",
		url: "https://leather.io/frequent-questions",
	},
	{
		text: "Install free",
		url: "https://leather.io/install-extension",
	},
];
