import RouterPage from "./router/RouterPage";

function App() {
	return (
		<div className="overflow-x-hidden">
			<RouterPage />
		</div>
	);
}

export default App;
