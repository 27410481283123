import React from "react";

function SwipeContent() {
	return (
		<div className="uppercase w-full mt-5 mb-10">
			<div className="flex overflow-x-auto overflow-y-hidden">
				<BigText text="BUY." />
				<BigText text="SEND." />
				<BigText text="sell." />
				<BigText text="BUY." />
				<BigText text="SEND." />
				<BigText text="sell." />
				<BigText text="BUY." />
				<BigText text="SEND." />
				<BigText text="sell." />
			</div>
			<div className="flex overflow-x-auto overflow-y-hidden justify-center hide-scrollbar">
				<BigText text="sell." />
				<BigText text="SECURE." />
				<BigText text="BUY." />
				<BigText text="SEND." />
				<BigText text="store." />
				<BigText text="sell." />
				<BigText text="SECURE." />
			</div>
			<div className="flex overflow-x-auto overflow-y-hidden">
				<BigText text="buy." />
				<BigText text="receive." />
				<BigText text="store." />
				<BigText text="secure." />
				<BigText text="buy." />
				<BigText text="receive." />
				<BigText text="buy." />
			</div>
		</div>
	);
}

export default SwipeContent;

function BigText({ text }) {
	return (
		<div className="w-full flex">
			<h1 className="font-headingBold text-7xl flex flex-nowrap">{text}</h1>
		</div>
	);
}
