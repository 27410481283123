import React from "react";
import HeroSection from "./sections/HeroSection";
import Counter from "./sections/Counter";
import LightBackground from "./sections/LightBackground";
import Footer from "../../components/footer/Footer";

function Landing() {
	return (
		<div>
			<HeroSection />
			<Counter />
			<LightBackground />
			<Footer />
		</div>
	);
}

export default Landing;
