import React from "react";
import Container from "../../layout/Container";
import CustomButton from "../../utils/custom/CustomButton";
import { Logo } from "../../assets/export/ExportSvg";

function Footer() {
	return (
		<div className="bg-primary py-20">
			<Container>
				<div className="space-y-20">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
						<h4 className="text-primary font-headingRegular text-3xl">
							Sign up for <br />
							our newsletter
						</h4>
						<div className="space-y-4">
							<input
								type="text"
								placeholder="Your email"
								className="w-full py-4 px-3 text-base rounded-[2px] bg-white text-primary placeholder:text-secondary"
							/>
							<CustomButton type="button" isWhite={true} full={true}>
								Sign Up
							</CustomButton>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
						<FooterLinks title="Product" data={product} />
						<FooterLinks title="Resources" data={resources} />
						<FooterLinks title="Legal" data={legal} />
						<FooterLinks title="Follow us" data={follow} />
					</div>

					<div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-10 lg:gap-0">
						<Logo />

						<div className="text-[#A2988E] text-sm lg:w-1/2 flex flex-col lg:flex-row gap-3 lg:gap-10">
							<p>© Leather Wallet LLC </p>
							<p>
								A{" "}
								<a
									href="https://trustmachines.co/"
									target="_blank"
									rel="noreferrer"
									className="text-[#C6BAAE] hover:underline hover:text-primary">
									Trust Machines
								</a>{" "}
								product
							</p>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Footer;

function FooterLinks({ title, data }) {
	return (
		<div className="space-y-6">
			<p className="text-primary text-base font-headingMedium font-semibold">
				{title}
			</p>
			<ul className="flex flex-col gap-4">
				{data.map((item, index) => (
					<li className="">
						<a
							href={item.url}
							target="_blank"
							rel="noreferrer"
							className="text-[#A2988E] text-base hover:underline hover:text-primary">
							{item.text}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}

const product = [
	{
		text: "Install browser extension",
		url: "https://leather.io/install-extension",
	},
	{
		text: "Install desktop wallet",
		url: "https://leather.io/install-desktop",
	},
	{
		text: "Compare versions",
		url: "https://leather.io/wallet-apps",
	},
	{
		text: "Request features",
		url: "https://leather.canny.io/feature-requests",
	},
];
const resources = [
	{
		text: "Blog",
		url: "https://leather.io/blog",
	},
	{
		text: "User guides",
		url: "https://leather.io/user-guides",
	},
	{
		text: "Developer docs",
		url: "https://leather.io/developer-docs",
	},
	{
		text: "Frequent questions",
		url: "https://leather.io/frequent-questions",
	},
];
const legal = [
	{
		text: "Privacy policy",
		url: "https://leather.io/privacy-policy",
	},
	{
		text: "Terms and services",
		url: "https://leather.io/terms",
	},
	{
		text: "Brand guidelines",
		url: "https://www.notion.so/trustmachines/Brand-guidelines-for-Leather-a5580b86cf764fa2a1fa5611b2105b9a",
	},
];
const follow = [
	{
		text: "X / Twitter",
		url: "https://twitter.com/LeatherBTC",
	},
	{
		text: "GitHub",
		url: "https://github.com/leather-wallet",
	},
	{
		text: "Discord",
		url: "https://discord.com/invite/67cZyTz8w6",
	},
];
