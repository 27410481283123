import React, { useState } from "react";
import Container from "../../../layout/Container";
import { FiPlus } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import SwipeContent from "./SwipeContent";
import Ecosystem from "./Ecosystem";
import AskUsrs from "./AskUsrs";
import {
	CoinHead,
	FireIcon,
	SafeIcon,
	WomanCoin,
} from "../../../assets/export/ExportSvg";

function LightBackground() {
	return (
		<div className="bg-lightWhite py-10">
			<SwipeContent />
			<Container>
				<div className="space-y-28">
					<div
						className="grid grid-cols-2 lg:grid-cols-4"
						style={{ rowGap: "1px", columnGap: "1px" }}>
						{dataTurnOver.map((item, index) => (
							<GridBox
								key={index}
								title={item.title}
								topic={item.topic}
								image={item.image}
								text={item.text}
							/>
						))}
					</div>

					<div className="space-y-10">
						<div className="lg:w-1/2">
							<h2 className="text-4xl lg:text-6xl font-headingBold">
								PUT YOUR <br /> BITCOIN-BACKED ASSETS TO WORK
							</h2>
						</div>

						<div>
							<div className="grid grid-cols-1 lg:grid-cols-2">
								<TextBox
									top="Secure management of tokens and collectibles"
									bottom="Receive, send, swap and deploy tokens and collectibles secured by Bitcoin"
								/>
								<ImageBox icon={<WomanCoin className="w-[50%]" />} />
								<div className="hidden lg:flex">
									<ImageBox icon={<CoinHead className="w-[50%] " />} />
								</div>
								<TextBox
									top="Bitcoin layers 
                                    with Stacks"
									bottom="Move between Bitcoin and Stacks, an integrated Layer 2 for the deployment of Bitcoin-backed assets in smart contracts"
								/>
								<div className="lg:hidden">
									<ImageBox icon={<CoinHead className="w-[50%] " />} />
								</div>

								<TextBox
									top="Ledger hardware support"
									bottom="Deploy your Bitcoin-based assets in Web3 apps while maintaining the benefits of cold storage"
								/>
								<ImageBox icon={<SafeIcon className="w-[50%]" />} />
								<div className="hidden lg:flex">
									<ImageBox icon={<FireIcon className="w-[50%]" />} />
								</div>
								<TextBox
									top="Lightning Network"
									showComing={true}
									bottom="Instantly swap assets between Bitcoin and Stacks via the Lightning Network"
								/>
								<div className="lg:hidden">
									<ImageBox icon={<FireIcon className="w-[50%]" />} />
								</div>
							</div>
						</div>
					</div>

					<Ecosystem />
					<AskUsrs />
				</div>
			</Container>
		</div>
	);
}

export default LightBackground;

function GridBox({ title, topic, image, text }) {
	const [displayTopic, setDisplayTopic] = useState(false);
	const [isHover, setIsHover] = useState(false);

	const handleButtonClick = () => {
		setDisplayTopic((prevDisplay) => !prevDisplay);
	};
	return (
		<div className="w-full">
			{!displayTopic ? (
				<div
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					className={`${
						isHover ? "bg-deepWhite" : "bg-white"
					}  p-6 hover:bg-deepWhite h-[370px] flex flex-col items-center justify-between`}>
					<p
						className={`text-center font-headingRegular text-secondary text-lg`}>
						{title}
					</p>
					<h3 className={`text-center font-headingRegular text-body text-3xl`}>
						{topic}
					</h3>
					<div className="w-full flex items-center justify-center py-5">
						<img
							src={image}
							alt=""
							className="w-[148px] h-[148px] object-cover"
						/>
					</div>
					<div className="w-full flex items-center justify-end ">
						<button
							onClick={handleButtonClick}
							className={`${
								isHover ? "bg-[#ab947e] text-primary" : "bg-[#DED6CD] text-body"
							}  p-2 rounded-full text-xl`}>
							{displayTopic ? <MdOutlineClose /> : <FiPlus />}
						</button>
					</div>
				</div>
			) : (
				<div
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					className={`${
						isHover ? "bg-secondary" : "bg-secondary"
					}  p-6 h-[370px] flex flex-col items-center justify-between`}>
					<p className={`text-center font-headingRegular text-primary text-lg`}>
						{title}
					</p>
					<h3 className={`text-center font-headingRegular text-white text-3xl`}>
						{topic}
					</h3>
					<div className="w-full flex items-center justify-center py-5">
						<p className="text-center text-base text-primary">{text}</p>
					</div>
					<div className="w-full flex items-center justify-end ">
						<button
							onClick={handleButtonClick}
							className={`p-2 rounded-full text-xl bg-primary text-primary hover:bg-deepWhite hover:text-[#ab947e]`}>
							{displayTopic ? <MdOutlineClose /> : <FiPlus />}
						</button>
					</div>
				</div>
			)}
		</div>
	);
}

const dataTurnOver = [
	{
		title: "Manage",
		topic: "Bitcoin",
		image:
			"https://framerusercontent.com/images/X3GbORuoHfBx0SsjGZ68I4bOZ0Q.png",
		text: "Deposit BTC for secure management and usage in Bitcoin apps",
	},
	{
		title: "Manage",
		topic: "Stacks",
		image:
			"https://framerusercontent.com/images/OjOgivrpUSIXcvILHzuvn3l8jdI.png",
		text: "Buy STX for usage in L2 apps on the Stacks network",
	},
	{
		title: "Trade",
		topic: "Ordinals",
		image: "https://framerusercontent.com/images/pkaVU73rRgxq7eS31McnGDqI.png",
		text: "Inscribe, collect, and trade Ordinals inscriptions on some of the biggest marketplaces",
	},
	{
		title: "Register",
		topic: "BNS names",
		image:
			"https://framerusercontent.com/images/3eeWuXwaqe7H0zR8v7unPgX8weo.png",
		text: "Register, collect, and trade using your unique BNS domain names",
	},
	{
		title: "Trade",
		topic: "Stacks NFTs",
		image:
			"https://framerusercontent.com/images/HNwbfy6OpVuDQWYhZU4xEL7ZSk.png",
		text: "Mint, buy, collect, and trade your own Stacks non-fungible token collections",
	},
	{
		title: "Mint",
		topic: "Stamps",
		image:
			"https://framerusercontent.com/images/E2XBmluYhusNEfdWp6syCC2DAck.png",
		text: "Mint, collect, and trade Bitcoin Stamps on emerging and top-tier Stamps hubs",
	},
	{
		title: "Trade",
		topic: "BRC-20",
		image: "https://framerusercontent.com/images/mlDCiKn8wIkhaRlxkhwuk7ByY.png",
		text: "Mint, store, send and trade BRC-20 tokens across marketplaces",
	},
	{
		title: "Store",
		topic: "SRC-20",
		image:
			"https://framerusercontent.com/images/KePqwHY4hfNLYPn9Jp0xMF2X7No.png",
		text: "Mint and store SRC-20 tokens",
	},
];

function TextBox({ top, bottom, showComing }) {
	return (
		<div className="bg-white h-[420px] text-body w-full p-4 flex flex-col items-start justify-between">
			<div>
				<h4 className="font-headingMedium text-4xl lg:text-[53px] leading-tight">
					{top}
				</h4>
				{showComing && (
					<p className="text-secondary text-base font-headingRegular">
						Coming soon
					</p>
				)}
			</div>
			<p className="font-headingMedium text-base lg:text-lg">{bottom}</p>
		</div>
	);
}

function ImageBox({ icon }) {
	return (
		<div className="bg-primary h-[420px] w-full p-4 flex items-center justify-center">
			{icon}
		</div>
	);
}
