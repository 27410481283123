import React from "react";

function CustomButton({
	full,
	isWhite,
	isDisabled,
	outlined,
	small,
	children,
	...props
}) {
	return (
		<button
			className={`${full ? "w-full" : ""} ${
				isDisabled ? "bg-opacity-20" : ""
			} ${isWhite ? "bg-lightWhite text-body " : "bg-primary text-primary"} ${
				small ? "py-2 px-8" : "py-5 px-8"
			}  rounded-[2.5px] font-headingMedium text-base flex items-center justify-center`}
			{...props}>
			{children}
		</button>
	);
}

export default CustomButton;
