import React from "react";
import { Twitter } from "../../../assets/export/ExportSvg";

function AskUsrs() {
	return (
		<div>
			<div className="space-y-10">
				<div className="w-full flex items-center justify-between">
					<h2 className="text-4xl lg:text-6xl font-headingBold">
						ASK OUR USERS
					</h2>
					<div className="flex items-center">
						<p className="text-xs lg:text-base">Follow us on</p>
						<Twitter className="w-[24px] h-[19px]" />
					</div>
				</div>

				<div
					className="border-t border-b border-deepWhite bg-deepWhite"
					style={{
						borderTopWidth: "1.5px",
						borderBottomWidth: "1.5px",
					}}>
					<div
						className="grid grid-cols-1 lg:grid-cols-2 border-b border-deepWhite"
						style={{
							rowGap: "1.5px",
							columnGap: "1.5px",
							borderBottomWidth: "1.5px",
						}}>
						{firstData.map((item, index) => (
							<DataTemp key={index} data={item} />
						))}
					</div>
					<div
						className="grid grid-cols-1 lg:grid-cols-3"
						style={{
							rowGap: "1.5px",
							columnGap: "1.5px",
						}}>
						{secondData.map((item, index) => (
							<DataTemp key={index} data={item} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AskUsrs;

function DataTemp({ data }) {
	return (
		<div className="p-4 bg-lightWhite">
			<div>
				<img
					src={data.image}
					alt=""
					className="w-[40px] h-[40px] rounded-full"
				/>
				<div className="mt-4 flex gap-1 items-center">
					<p className="font-headingMedium text-lg">{data.name}</p>
					{data.isVerified && (
						<img
							src="https://framerusercontent.com/images/nLxg2RFaX5VkRGlCj1EXA29o.png"
							alt=""
							className="w-[16px] h-[16px]"
						/>
					)}
				</div>
				<p>{data.handle}</p>
			</div>

			<p className="text-base font-headingMedium mt-5">{data.post}</p>
		</div>
	);
}

const firstData = [
	{
		image: "https://framerusercontent.com/images/9N8b8tV6E83of26sRTGANPF3E.jpg",
		name: "Aubrey Strobel",
		handle: "@VelarBTC",
		post: "To get Bitcoin to a billion users, we need a wallet appropriate for 2023.  As a long-time Hiro user, this is an exciting rebrand to  @LeatherBTC!",
		isVerified: true,
	},
	{
		image:
			"https://framerusercontent.com/images/XeoQkaS4H3V9ji7kk3T2sXsad4.jpg",
		name: "Velar",
		handle: "@VelarBTC",
		post: "Congrats to our Partners! 🟧 We all love carrying Leather wallet in our pocket for its Utility and Brag-worthiness! We highly recommend everyone to try it atleast once! 😃",
		isVerified: false,
	},
];
const secondData = [
	{
		image:
			"https://framerusercontent.com/images/dDh1sDrg0Vjq6QRYJsJL2t3aoY4.jpg",
		name: "Hermetica | Bitcoin DeFi",
		handle: "@HermeticaFi",
		post: "Thrilled to witness @leatherbtc embark on their new journey!  We've always been fans, and their fresh UI + upcoming features are testament to the boundless potential of #bitcoin DeFi📈",
		isVerified: true,
	},
	{
		image:
			"https://framerusercontent.com/images/aQccJoatdfG7cd0ieVZ5ToP7ww.jpg",
		name: "OrdinalsBot ⚡",
		handle: "@ordinalsbot",
		post: "Cheers to Team Leather! 🙌 Loving that subtle tribute to wallets that truly last.🧡",
		isVerified: false,
	},
	{
		image:
			"https://framerusercontent.com/images/1GB37nHatNx01BT2yxIUbeBrzig.jpg",
		name: "teflonmusk.btc/.eth/.tez",
		handle: "@MuskTeflon",
		post: "Ngl this rebrand is fresh🤌",
		isVerified: true,
	},
];
