import React from "react";

function Counter() {
	return (
		<div className="bg-secondary py-10 w-full">
			<div className="w-full max-w-screen-lg mx-auto flex flex-col lg:flex-row gap-10 lg:gap-0 items-center justify-between">
				<CountTemplate amount="375K+" text="Total downloads to date" />
				<CountTemplate amount="100K+" text="Active monthly users" />
				<CountTemplate amount="300K+" text="Transactions per month" />
			</div>
		</div>
	);
}

export default Counter;

function CountTemplate({ amount, text }) {
	return (
		<div className="space-y-1">
			<h1 className="font-headingBold text-primary text-center text-6xl">
				{amount}
			</h1>
			<p className="text-secondary text-center text-base font-headingMedium">
				{text}
			</p>
		</div>
	);
}
