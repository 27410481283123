import React from "react";

function CustomInput({ ...props }) {
	return (
		<input
			className="w-full py-4 px-2 bg-transparent border border-lightWhite text-primary focus:ring-0 focus:outline-none caret-[#F6F1EE] rounded-[2px]"
			{...props}
		/>
	);
}

export default CustomInput;
