import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "../pages/landing/Landing";
import SignIn from "../pages/sign-in/Signin";

function RouterPage() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Landing />} />
				<Route exact path="/sign-in" element={<SignIn />} />
			</Routes>
		</Router>
	);
}

export default RouterPage;
