import React, { useState } from "react";
import { MdArrowOutward } from "react-icons/md";
import { Arrows, Check, Shield } from "../../../assets/export/ExportSvg";

function Ecosystem() {
	return (
		<>
			<div className="space-y-10">
				<div className="lg:w-2/3">
					<h2 className="text-4xl lg:text-6xl font-headingBold">
						CONNECT TO AN EMERGING ECOSYSTEM OF BITCOIN APPS
					</h2>
				</div>
				<div
					className="grid grid-cols-1 lg:grid-cols-3 border-t border-b border-deepWhite bg-deepWhite"
					style={{
						rowGap: "1.5px",
						columnGap: "1.5px",
						borderTopWidth: "1.5px",
						borderBottomWidth: "1.5px",
					}}>
					{datas.map((item, index) => (
						<Template key={index} data={item} />
					))}
				</div>
			</div>

			<div className="space-y-10">
				<div className="w-full">
					<h2 className="text-4xl lg:text-6xl font-headingBold">
						TRANSPARENT, <br /> RELIABLE AND AUDITED
					</h2>
				</div>
				<div
					className="grid grid-cols-1 lg:grid-cols-3 border-t border-b border-deepWhite bg-deepWhite"
					style={{
						rowGap: "1.5px",
						columnGap: "1.5px",
						borderTopWidth: "1.5px",
						borderBottomWidth: "1.5px",
					}}>
					{data2.map((item, index) => (
						<Template2 key={index} data={item} />
					))}
				</div>
			</div>
		</>
	);
}

export default Ecosystem;

function Template({ data }) {
	const [isHover, setIsHover] = useState(false);
	return (
		<a
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			href={data.url}
			target="_blank"
			rel="noreferrer"
			className="w-full p-5 bg-lightWhite hover:bg-deepWhite relative">
			<div className="space-y-8">
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-3">
						<img src={data.image} alt="" className="w-[40px] h-[40px]" />
						<p>{data.name}</p>
					</div>

					<MdArrowOutward
						className={`${isHover ? "flex" : "hidden"} text-xl`}
					/>
				</div>

				<p>{data.text}</p>
			</div>
		</a>
	);
}
function Template2({ data }) {
	const [isHover, setIsHover] = useState(false);
	return (
		<a
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			href={data.url}
			target="_blank"
			rel="noreferrer"
			className="w-full p-5 bg-lightWhite hover:bg-deepWhite relative">
			<div className="space-y-8">
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-3 ">
						<div className="w-[48px] h-[48px]">{data.icon}</div>
					</div>

					<MdArrowOutward
						className={`${isHover ? "flex" : "hidden"} text-xl`}
					/>
				</div>

				<div className="space-y-3">
					<h5 className="text-3xl font-headingMedium">{data.title}</h5>
					<p className="text-base font-headingRegular">{data.text}</p>
				</div>
			</div>
		</a>
	);
}

const datas = [
	{
		image:
			"https://framerusercontent.com/images/0p4OQ7Fgd1LQazxlqcBpUMMTLc.png",
		name: "MagicEden",
		text: "Connect your Leather wallet to buy, sell, and trade Bitcoin NFTs on one of the top NFT marketplaces",
		url: "https://magiceden.io/",
	},
	{
		image:
			"https://framerusercontent.com/images/qu5wDU21AEDl7Hnt1MIAnmrDPs.png",
		name: "Alex",
		text: "Plug into the latest trends and services in the Bitcoin DeFi landscape",
		url: "https://alexgo.io/",
	},
	{
		image: "https://framerusercontent.com/images/JEA2PlPmS6rfgh4OvJuVa3Gsg.png",
		name: "Gamma",
		text: "Inscribe, buy and sell Ordinals on one of the biggest Ordinals marketplaces",
		url: "https://gamma.io/",
	},
	{
		image: "https://framerusercontent.com/images/begFatAWYJTusnJWQ9fSwLe4.png",
		name: "BTC.us",
		text: "Register and manage your unique .btc domain with Leather",
		url: "https://btc.us/",
	},
	{
		image:
			"https://framerusercontent.com/images/tBBkii37u4O6yGgsVi9Q0TxXe0.png",
		name: "Arkadiko",
		text: "Gain access to self-repaying loans on Bitcoin via a decentralized, non-custodial liquidity protocol",
		url: "https://arkadiko.finance/",
	},
	{
		image:
			"https://framerusercontent.com/images/qhxpNO1TmOFQtTc63Oq1I6GQavA.png",
		name: "Lockstacks",
		text: "Participate in the Stacking process and contribute to a key network consensus mechanism",
		url: "https://lockstacks.com/sign-in?chain=mainnet",
	},
	{
		image:
			"https://framerusercontent.com/images/hX9jQANEdxOUXESVCWMjWQReWo.png",
		name: "stackswap",
		text: "Swap your tokens and contribute to liquidity pools with an AI-powered Bitcoin DeFi protocol",
		url: "https://app.stackswap.org/",
	},
	{
		image: "https://framerusercontent.com/images/tIlgyv7rkxlXjx1WcCusxnQs.svg",
		name: "Console",
		text: "Connect to a Web3 chat app that prioritizes identity ownership and privacy",
		url: "https://www.console.xyz/",
	},
	{
		image:
			"https://framerusercontent.com/images/x6GOahu0o3ZdGWiRp2K1hKuSDo.png",
		name: "Sigle",
		text: "Unlock premium tools designed for growing content platforms with your NFTs",
		url: "https://www.sigle.io/",
	},
];

const data2 = [
	{
		title: "100% non-custodial and open-source",
		text: "Secured through rigorous community-based testing and review",
		icon: <Arrows className="w-[48px] h-[48px]" />,
		url: "https://github.com/leather-wallet",
	},
	{
		title: "Audited by security professionals",
		text: "A leading security consultancy with experience in the crypto space ensuring privacy and security.",
		icon: <Shield className="w-[48px] h-[48px]" />,
		url: "https://github.com/leather-wallet/extension/blob/main/public/docs/least-authority-security-audit-report.pdf",
	},
	{
		title: "Field tested by bug bounty program",
		text: "Tested by cybersecurity professionals to eliminate application vulnerabilities",
		icon: <Check className="w-[48px] h-[48px]" />,
		url: "https://hackerone.com/leather_wallet/",
	},
];
