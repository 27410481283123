import React from "react";
import Container from "../../../layout/Container";
import CustomButton from "../../../utils/custom/CustomButton";
import NavBar from "../../../components/nav/NavBar";
import Key from "../../../assets/key.svg";
import Diamond from "../../../assets/diamond.webp";
import { Link } from "react-router-dom";

function HeroSection() {
	return (
		<div className="bg-primary min-h-screen">
			<NavBar />
			<div className="pt-16 relative">
				<Container>
					<div className="w-full max-w-3xl space-y-5">
						<h1 className="text-primary text-5xl lg:text-[94px] font-headingBold ">
							THE BITCOIN WALLET FOR THE REST <br className="md:hidden" /> OF US
						</h1>
						<p className="font-headingRegular text-primary text-xl">
							Tap into the Bitcoin economy with Leather
						</p>
						<div className="py-4">
							<Link to="/sign-in">
								<CustomButton isWhite={true}>Install Leather now</CustomButton>
							</Link>
						</div>
					</div>

					{/* key  */}
				</Container>

				<img
					src={Diamond}
					alt=""
					className="absolute right-[13%] lg:right-[49.6%] top-[15%] lg:top-[16%] w-[73.26px] lg:w-[113px] transform rotate-[20deg]"
				/>
				<img
					src={Key}
					alt=""
					className="absolute right-[10%] lg:right-[34%] top-[20%] lg:top-[23%] w-[73.26px] lg:w-[113px] transform -rotate-[12deg]"
				/>
			</div>
		</div>
	);
}

export default HeroSection;
